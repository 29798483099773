import styled from "@emotion/styled";
import { Box, Fade, Menu, Typography } from "@mui/material";
import Image from "next/image";
import Link from "next/link";
import { Fragment } from "react";

const NoChildBox = styled(Box)`
  padding: 5px 0;
  min-width: 200px;
  max-width: 270px;
`;

const ImageText2 = styled(Typography)`
  font-family:
    DIN Neuzeit Grotesk,
    sans-serif;
  font-weight: 300;
  font-style: normal;
  color: #6a6a6a;
  transition: color 0.2s ease-in-out;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  font-size: 11px;
  text-align: center;
`;

const ImageText = styled(Typography)`
  font-family:
    DIN Neuzeit Grotesk,
    sans-serif;
  font-weight: 300;
  font-style: normal;
  color: ${({ val2 }) => (+val2 ? "6a6a6a" : "#1c1b1b")};
  transition: color 0.2s ease-in-out;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  font-size: ${({ val2 }) => (+val2 ? "11px" : "12px")};
  margin-bottom: 6px;
  margin-top: 10px;
  text-align: center;
`;

const ChildHeader = styled(Typography)`
  font-family:
    DIN Neuzeit Grotesk,
    sans-serif;
  font-weight: 300;
  font-style: normal;
  color: #6a6a6a;
  transition: color 0.2s ease-in-out;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  font-size: 11px;
  margin-bottom: 10px;
`;

const SubChildText = styled(Typography)`
  font-family: Futura, sans-serif;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  text-transform: capitalize;
  color: #1c1b1b;
  background: #fff;
  line-height: 1.5;
  margin-top: 10px;
  &:hover {
    color: #6a6a6a;
  }
`;

const NoChildTypography = styled(Typography)`
  font-family: Futura, sans-serif;
  font-weight: 400;
  font-style: normal;
  background: #fff;
  line-height: 1.5;
  display: flex;
  padding-left: 25px;
  padding-right: 40px;
  color: #1c1b1b;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-size: 14px;
  text-transform: capitalize;
  &:hover {
    color: #6a6a6a;
  }
`;

const ImageDiv = styled.div`
  position: relative;
  height: auto;
  min-height: 10vh;
  overflow: hidden;
  margin-right: 20px;
  margin-top: 20px;
  justify-content: center;
`;

export default function DesktopMenu({
  item,
  handleClose,
  open,
  labelId,
  anchorEl,
  parentIdx,
}) {
  return (
    <Menu
      id="fade-menu"
      anchorEl={anchorEl}
      MenuListProps={{
        "aria-labelledby": labelId,
      }}
      className={`C003-${parentIdx}-DesktopMenu`}
      open={open}
      onClose={handleClose}
      TransitionComponent={Fade}
      PaperProps={{
        style: {
          width:
            item[0] && item[0].children && item[0].children.length
              ? "100%"
              : "fit-content",
          maxHeight: "450px",
          boxShadow: "none",
          padding: "15px 0px",
          overflowY: "auto",
          zIndex: "99999999999",
          borderRadius: "0 0 3px 3px",
          borderLeft: "1px solid #e1e3e4",
          borderRight: "1px solid #e1e3e4",
          borderBottom: "1px solid #e1e3e4",
        },
      }}
    >
      <Box
        display="flex"
        flexDirection={
          item[0] && item[0].children && item[0].children.length
            ? "row"
            : "column"
        }
        justifyContent="center"
        alignItems="flex-start"
        padding="20px 0px 0px"
        key={item.value}
      >
        {item.map(({ children, value, value2, link, image }, idx) => {
          if (children && children.length) {
            return (
              <Fragment key={idx}>
                {!image ? (
                  <Box
                    display="flex"
                    flexDirection="column"
                    key={value}
                    margin="5px"
                    marginRight="80px"
                    className={`C003-${parentIdx}-DesktopMenu-${idx}`}
                  >
                    <>
                      <Link
                        href={link}
                        className={`C003-${parentIdx}-DesktopMenu-${idx}`}
                      >
                        <ChildHeader
                          className={`C003-${parentIdx}-DesktopMenu-${idx}`}
                        >
                          {value}
                        </ChildHeader>
                      </Link>
                      {children.map((subChildItem, subChildIdx) => {
                        return (
                          <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            key={subChildItem.value}
                            className={`C003-${parentIdx}-DesktopMenu-${idx}-${subChildIdx}`}
                          >
                            <Link
                              href={subChildItem.link}
                              className={`C003-${parentIdx}-DesktopMenu-${idx}-${subChildIdx}`}
                            >
                              <SubChildText
                                onClick={handleClose}
                                className={`C003-${parentIdx}-DesktopMenu-${idx}-${subChildIdx}`}
                              >
                                {subChildItem.value}
                              </SubChildText>
                            </Link>
                          </Box>
                        );
                      })}
                    </>
                  </Box>
                ) : (
                  <Link
                    href={link}
                    className={`C003-${parentIdx}-DesktopMenu-${idx}-image`}
                  >
                    <ImageDiv>
                      <Image
                        onClick={handleClose}
                        src={image}
                        alt="menu-bar-image"
                        width={300}
                        height={300}
                        loading="eager"
                        fetchPriority="high"
                        unoptimized={true}
                        style={{ objectFit: "cover" }}
                        className={`C003-${parentIdx}-DesktopMenu-${idx}-image`}
                      />
                      <ImageText
                        onClick={handleClose}
                        className={`C003-${parentIdx}-DesktopMenu-${idx}-image`}
                      >
                        {value}
                      </ImageText>
                      <ImageText2
                        className={`C003-${parentIdx}-DesktopMenu-${idx}-image`}
                      >
                        {value2}
                      </ImageText2>
                    </ImageDiv>
                  </Link>
                )}
              </Fragment>
            );
          }
          return (
            <NoChildBox
              key={value}
              className={`C003-${parentIdx}-DesktopMenu-${idx}`}
            >
              <Link
                href={link}
                style={{ color: "#222" }}
                className={`C003-${parentIdx}-DesktopMenu-${idx}`}
              >
                <NoChildTypography
                  className={`C003-${parentIdx}-DesktopMenu-${idx}`}
                >
                  {value}
                </NoChildTypography>
              </Link>
            </NoChildBox>
          );
        })}
      </Box>
    </Menu>
  );
}
