import(/* webpackMode: "eager" */ "/home/ubuntu/merca-micro-LP_DOODLAGE/app/globals.css");
import(/* webpackMode: "eager" */ "/home/ubuntu/merca-micro-LP_DOODLAGE/components/Container/index.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/merca-micro-LP_DOODLAGE/components/footer.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/merca-micro-LP_DOODLAGE/components/header.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/merca-micro-LP_DOODLAGE/lib/registry.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/merca-micro-LP_DOODLAGE/node_modules/@mui/material-nextjs/v13-appRouter/appRouterV13.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/merca-micro-LP_DOODLAGE/node_modules/@mui/material/node/CssBaseline/index.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/merca-micro-LP_DOODLAGE/node_modules/@next/third-parties/dist/google/ga.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/merca-micro-LP_DOODLAGE/node_modules/@next/third-parties/dist/google/gtm.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/merca-micro-LP_DOODLAGE/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
import(/* webpackMode: "eager" */ "/home/ubuntu/merca-micro-LP_DOODLAGE/node_modules/next/dist/client/script.js")