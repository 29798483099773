import config from "config/env";

export const MenuItems = [
  {
    value: "SHOP",
    link: "#",
    children: [
      {
        value: "Categories",
        link: "#",
        children: [
          {
            value: "New In",
            link: `/collections/669f96a1a19786a887275965`,
          },
          {
            value: "Dresses & Tunics",
            link: `/collections/669f96a1a19786a887275932`,
          },
          {
            value: "Tops & Shirts",
            link: `/collections/669f96a1a19786a887275994`,
          },
          {
            value: "Bottoms",
            link: `/collections/669f96a1a19786a887275913`,
          },
          {
            value: "Sets",
            link: `/collections/669f96a1a19786a88727591b`,
          },
          {
            value: "Jackets",
            link: `/collections/669f96a1a19786a88727596d`,
          },
          {
            value: "Others",
            link: `/collections/66a73a6ea19786a8872d0da3`,
          },
        ],
      },
      {
        value: "Collections",
        link: "#",
        children: [
          {
            value: "Made From Factory Waste",
            link: `/collections/669f96a1a19786a887275906`,
          },
          {
            value: "Made From Consumer Waste",
            link: `/collections/669f96a1a19786a887275905`,
          },
          {
            value: "Made To Be Zero Waste",
            link: `/collections/669f96a1a19786a8872758fb`,
          },
          {
            value: "It's Organic",
            link: `/collections/669f96a1a19786a887275969`,
          },
          {
            value: "Artisanal Prints",
            link: `/collections/669f96a1a19786a88727590a`,
          },
          {
            value: "Eco Essentials",
            link: `/collections/669f96a1a19786a88727590e`,
          },
          {
            value: "Thoughtful Prints",
            link: `/collections/669f96a1a19786a887275903`,
          },
          {
            value: "Genderless Shirts",
            link: `/collections/669f96a1a19786a88727599a`,
          },
        ],
      },
      {
        value: "Collaborations",
        link: "#",
        children: [
          {
            value: "Doodlage X Mud & Moon",
            link: `/collections/669f96a1a19786a88727592b`,
          },
          {
            value: "Doodlage X No More",
            link: `/collections/66ac9c90a19786a88733b850`,
          },
        ],
      },
      {
        value: "Eco Chic On A Budget",
        value2: "Pocket friendly styles",
        image:
          "https://doodlage.in/cdn/shop/files/IMG_6889.jpg?v=1720166391&width=315",
        link: `/collections/669f96a1a19786a887275971`,
        children: [{}],
      },
      {
        value: "Genderless Shirts",
        value2: "Start Twinning",
        image:
          "https://doodlage.in/cdn/shop/files/bluetshirt_ab6c7aa3-26be-4944-af97-9a70bde09acb.png?v=1709814364&width=315",
        link: `/collections/669f96a1a19786a88727599a`,
        children: [{}],
      },
    ],
  },
  {
    value: "SALE",
    link: `/collections/669f96a1a19786a887275908`,
    children: [],
  },
  {
    value: "SUSTAINABILITY",
    link: `${config.brandURL}`,
    children: [
      {
        value: "Our Story",
        link: `${config.brandURL}/pages/contact-us`,
      },
      {
        value: "Our Projects",
        link: `${config.brandURL}/blogs/work-with-us`,
      },
      {
        value: "Press",
        link: `${config.brandURL}/blogs/press`,
      },
    ],
    utm: true,
  },
];
