"use client";
const { Container } = require("@mui/material");
const { default: styled } = require("styled-components");

const GlobalContainer = styled(Container)`
  width: 100% !important;
  margin: 0 auto;
  padding: 0 2rem !important;
  @media only screen and (max-width: 1000px) {
    padding: 0 1rem !important;
  }
`;

export default GlobalContainer;
