"use client";
import Link from "next/link";
import Image from "next/image";
import styled from "@emotion/styled";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid,
  Typography,
  Box,
} from "@mui/material";

import config from "config/env";
import { setURL } from "utils/outclicks";
import { SearchParamsHook } from "lib/utm";
import { ExpandMore } from "@mui/icons-material";

const Logo = styled(Grid)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  padding: 20px 12px 10px !important;
  a {
    display: flex;
    align-items: center;
  }
  @media only screen and (max-width: 600px) {
    padding: 20px 12px !important;
  }
`;

const FooterText = styled(Typography)`
  font-family: Futura, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  color: #6a6a6a;
  line-height: 1.5;
  text-align: center;
  padding: 4px 0px;
  margin-bottom: 4px;
  @media only screen and (max-width: 600px) {
    font-size: 14px;
  }
`;

const FooterTitle = styled(Typography)`
  text-transform: uppercase;
  letter-spacing: 0.3em;
  font-family: DIN Neuzeit Grotesk;
  color: #1a1a1a;
  font-size: 16px;
  display: block;
  padding: 5px 0px;
  text-align: center;
  @media only screen and (max-width: 600px) {
    font-size: 11.776px;
  }
`;

const FooterLinks1 = [
  {
    text: "Terms and Conditions",
    link: `${config.brandURL}/pages/term-conditions`,
    className: "C012-menu-terms",
  },
  {
    text: "Privacy",
    link: `${config.brandURL}/pages/privacy-policy`,
    className: "C012-menu-privacy",
  },
];

const FooterLinks2 = [
  {
    text: "Return Policy",
    link: `${config.brandURL}/pages/return-refunds`,
    className: "C012-menu-return",
  },
  {
    text: "Shipping & Delivery",
    link: `${config.brandURL}/pages/shipping-policy`,
    className: "C012-menu-shipping",
  },
];

const MobileFooterDiv = styled.div`
  @media only screen and (min-width: 751px) {
    display: none;
  }
`;

const DesktopFooterDiv = styled.div`
  @media only screen and (max-width: 750px) {
    display: none;
  }
`;

const LogoImage = ({ queryParams }) => (
  <Link href={setURL(`${config.brandURL}`, queryParams)} className="C012-logo">
    <Image
      src="/static/logo.png"
      height={34}
      width={170}
      alt="logo"
      priority
      loading="eager"
      fetchPriority="high"
      unoptimized={true}
      className="C012-logo"
    />
  </Link>
);

const FooterLinks3 = [
  {
    text: "FAQ's",
    link: `${config.brandURL}/pages/faqs-1`,
    className: "C012-menu-faq",
  },
  {
    text: "Contact Us",
    link: `${config.brandURL}/pages/contact-us`,
    className: "C012-menu-contact",
  },
];

export default function Footer() {
  const queryParams = SearchParamsHook();
  return (
    <>
      <MobileFooterDiv className="C012">
        <Logo className="C012-logo">
          <LogoImage className="C012-logo" />
        </Logo>
        <Accordion
          disableGutters
          sx={{
            border: "1px solid #e8e8e1",
            borderBottom: "0px",
            "&::before": { backgroundColor: "#e8e8e1" },
            boxShadow: "none",
            "& .MuiAccordionSummary-content": { justifyContent: "center" },
          }}
          className="C012-menu-0"
        >
          <AccordionSummary
            expandIcon={<ExpandMore className="C012-menu-0-expand" />}
            aria-controls="panel2-content"
            id="panel2-header"
            className="C012-menu-0"
          >
            <div className="C012-menu-0" style={{ width: "24px" }}></div>
            <FooterTitle className="C012-menu-0">Manage Orders</FooterTitle>
          </AccordionSummary>
          <AccordionDetails className="C012-menu-0">
            {FooterLinks2.map((item) => (
              <Link
                href={setURL(item.link, queryParams)}
                key={item.link}
                className={item.className}
              >
                <FooterText className={item.className}>{item.text}</FooterText>
              </Link>
            ))}
          </AccordionDetails>
        </Accordion>
        <Accordion
          disableGutters
          sx={{
            border: "1px solid #e8e8e1",
            borderBottom: "0px",
            "&::before": { backgroundColor: "#e8e8e1" },
            boxShadow: "none",
            "& .MuiAccordionSummary-content": { justifyContent: "center" },
          }}
          className="C012-menu-1"
        >
          <AccordionSummary
            expandIcon={<ExpandMore className="C012-menu-1-expand" />}
            aria-controls="panel3-content"
            id="panel3-header"
            className="C012-menu-1"
          >
            <div className="C012-menu-1" style={{ width: "24px" }}></div>
            <FooterTitle className="C012-menu-1">Information</FooterTitle>
          </AccordionSummary>
          <AccordionDetails className="C012-menu-1">
            {FooterLinks3.map((item) => (
              <Link
                href={setURL(item.link, queryParams)}
                key={item.link}
                className={item.className}
              >
                <FooterText className={item.className}>{item.text}</FooterText>
              </Link>
            ))}
          </AccordionDetails>
        </Accordion>
        <Accordion
          disableGutters
          sx={{
            border: "1px solid #e8e8e1",
            borderBottom: "0px",
            "&::before": { backgroundColor: "#e8e8e1" },
            boxShadow: "none",
            "& .MuiAccordionSummary-content": { justifyContent: "center" },
          }}
          className="C012-menu-2"
        >
          <AccordionSummary
            expandIcon={<ExpandMore className="C012-menu-2-expand" />}
            aria-controls="panel1-content"
            id="panel1-header"
            className="C012-menu-2"
          >
            <div className="C012-menu-2" style={{ width: "24px" }}></div>
            <FooterTitle className="C012-menu-2">Policies</FooterTitle>
          </AccordionSummary>
          <AccordionDetails className="C012-menu-2">
            {FooterLinks1.map((item) => (
              <Link
                href={setURL(item.link, queryParams)}
                key={item.link}
                className={item.className}
              >
                <FooterText className={item.className}>{item.text}</FooterText>
              </Link>
            ))}
            <FooterText className="C012-menu-year">
              © Doodlage Pvt. Ltd.
            </FooterText>
          </AccordionDetails>
        </Accordion>
        <Box height={"66px"} />
      </MobileFooterDiv>

      <DesktopFooterDiv className="C012">
        <Divider />
        <Grid container className="C012">
          <Logo item xs={12} className="C012-logo">
            <LogoImage className="C012-logo" />
          </Logo>
          <Logo item xs={4} className="C012-menu">
            <FooterTitle className="C012-menu-policy">Policies</FooterTitle>
            {FooterLinks1.map((item) => (
              <Link
                href={setURL(item.link, queryParams)}
                key={item.link}
                className={item.className}
              >
                <FooterText className={item.className}>{item.text}</FooterText>
              </Link>
            ))}
            <FooterText className="C012-menu-year">
              © Doodlage Pvt. Ltd.
            </FooterText>
          </Logo>
          <Logo item xs={4} className="C012-menu">
            <FooterTitle className="C012-menu-order">Manage Orders</FooterTitle>
            {FooterLinks2.map((item) => (
              <Link
                href={setURL(item.link, queryParams)}
                key={item.link}
                className={item.className}
              >
                <FooterText className={item.className}>{item.text}</FooterText>
              </Link>
            ))}
          </Logo>
          <Logo item xs={4} className="C012-menu">
            <FooterTitle className="C012-menu-info">Information</FooterTitle>
            {FooterLinks3.map((item) => (
              <Link
                href={setURL(item.link, queryParams)}
                key={item.link}
                className={item.className}
              >
                <FooterText className={item.className}>{item.text}</FooterText>
              </Link>
            ))}
          </Logo>
        </Grid>
        <Box height={"66px"} />
      </DesktopFooterDiv>
    </>
  );
}
