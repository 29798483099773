import { Fragment, useState } from "react";
import styled from "@emotion/styled";
import { Add, Close, ExpandMore, Remove } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Drawer,
  List,
  ListItem as MuiListItem,
  ListItemButton as MuiListItemButton,
  Typography,
} from "@mui/material";
import Link from "next/link";

import { MenuItems } from "utils/drawerItem";
import { SearchParamsHook } from "lib/utm";
import { setURL } from "utils/outclicks";

const ListItem = styled(MuiListItem)`
  display: flex;
  flex-direction: column;
  text-transform: capitalize;
  padding: 12px 20px;
  justify-content: left;
`;

const ListItemButton = styled(MuiListItemButton)`
  font-family:
    DIN Neuzeit Grotesk,
    sans-serif;
  font-weight: 300;
  font-style: normal;
  transition: color 0.2s ease-in-out;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  justify-content: left;
  text-transform: capitalize;
  font-size: 12px;
  justify-content: left;
  font-weight: 400;
  font-style: initial;
  line-height: 1.65;
  padding: 0px;
  color: #fff;
`;

const AccordionTypography = styled(Typography)`
  font-family:
    DIN Neuzeit Grotesk,
    sans-serif;
  font-weight: 300;
  font-style: normal;
  transition: color 0.2s ease-in-out;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  justify-content: left;
  font-size: 12px;
  font-weight: 400;
  font-style: initial;
  line-height: 1.65;
  color: #fff;
`;

const ChildTypography = styled(Typography)`
  font-family:
    DIN Neuzeit Grotesk,
    sans-serif;
  font-weight: 300;
  font-style: normal;
  transition: color 0.2s ease-in-out;
  letter-spacing: ${({ sub }) => (+sub ? "normal" : "0.2em")};
  text-transform: ${({ sub }) => (+sub ? "capitalise" : "uppercase")};
  font-size: 11px;
  padding: ${({ sub }) => (+sub ? "10px 0px" : "3px 0px 8px")};
  justify-content: left;
  font-weight: 400;
  line-height: 1.65;
  color: #ffffff80;
`;

export default function MobileDrawer(props) {
  const { open, setOpen } = props;
  const queryParams = SearchParamsHook();
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Drawer
      sx={{ zIndex: "999999999999" }}
      anchor="left"
      open={open}
      onClose={() => setOpen(false)}
      className="C004"
    >
      <Box
        sx={{ width: 300, background: "#1c1b1b", height: "100%" }}
        role="presentation"
        className="C004"
      >
        <Box
          display="flex"
          justifyContent="flex-start"
          padding="14px"
          className="C004"
        >
          <Close
            onClick={() => setOpen(false)}
            sx={{
              color: "#fff",
              paddingTop: 0,
              opacity: "0.6",
            }}
            className="C004-close"
          />
        </Box>
        <List
          sx={{ p: "0px", background: "#1c1b1b" }}
          className="C004-menu-items"
        >
          {MenuItems.map((item, idx) => {
            if (item.children.length) {
              return (
                <Fragment key={idx}>
                  <Box
                    padding="6px 20px"
                    bgcolor="#1c1b1b"
                    className={`C004-menu-items-${idx}`}
                  >
                    <Accordion
                      expanded={expanded === idx}
                      elevation={0}
                      disableGutters
                      onChange={handleChange(idx)}
                      sx={{
                        background: "#1c1b1b",
                        "& .MuiPaper-root": { backgroundColor: "#1c1b1b" },
                        "& .MuiAccordionDetails-root": {
                          pb: "5px",
                          pr: "0px",
                        },
                      }}
                      className={`C004-menu-items-${idx}`}
                    >
                      <AccordionSummary
                        expandIcon={
                          expanded === idx ? (
                            <Remove
                              fontSize="small"
                              sx={{ color: "#fff", opacity: "0.7" }}
                              className={`C004-menu-items-${idx}-expand-minus`}
                            />
                          ) : (
                            <Add
                              fontSize="small"
                              sx={{ color: "#fff", opacity: "0.7" }}
                              className={`C004-menu-items-${idx}-expand-add`}
                            />
                          )
                        }
                        sx={{
                          p: "0px",
                          "& .MuiAccordionSummary-content": {
                            margin: 0,
                            background: "#1c1b1b",
                          },
                        }}
                        className={`C004-menu-items-${idx}`}
                      >
                        <AccordionTypography
                          className={`C004-menu-items-${idx}`}
                        >
                          {item.value}
                        </AccordionTypography>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          background: "#1c1b1b !important",
                        }}
                        className={`C004-menu-items-${idx}-details`}
                      >
                        {item.children.map(
                          ({ children, value, value2, link }, Childidx) => {
                            if (children && children.length > 1) {
                              return (
                                <Accordion
                                  key={`${idx}-${Childidx}`}
                                  elevation={0}
                                  sx={{
                                    border: "0px solid #1c1b1b",
                                    "&::before": { backgroundColor: "#1c1b1b" },
                                    boxShadow: "none",
                                    "& .MuiPaper-root": {
                                      backgroundColor: "#1c1b1b",
                                    },
                                    "& .MuiAccordionDetails-root": {
                                      p: "0px",
                                      ml: "15px",
                                    },
                                  }}
                                  disableGutters
                                  className={`C004-menu-items-${idx}-details-${Childidx}`}
                                >
                                  <AccordionSummary
                                    expandIcon={
                                      <ExpandMore
                                        fontSize="20px"
                                        sx={{ color: "#ffffff80" }}
                                        className={`C004-menu-items-${idx}-details-${Childidx}-expand`}
                                      />
                                    }
                                    sx={{
                                      p: "0px",
                                      "& .MuiAccordionSummary-content": {
                                        margin: 0,
                                      },
                                      background: "#1c1b1b",
                                    }}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                    className={`C004-menu-items-${idx}-details-${Childidx}`}
                                  >
                                    <Box
                                      width="fit-content"
                                      sx={{
                                        background: "#1c1b1b",
                                        paddingLeft: "5px",
                                      }}
                                      className={`C004-menu-items-${idx}-details-${Childidx}`}
                                    >
                                      <Link
                                        href={link}
                                        style={{
                                          width: "100%",
                                          textAlign: "left",
                                          color: "#000",
                                        }}
                                        className={`C004-menu-items-${idx}-details-${Childidx}`}
                                      >
                                        <ChildTypography
                                          className={`C004-menu-items-${idx}-details-${Childidx}`}
                                        >
                                          {value}
                                        </ChildTypography>
                                      </Link>
                                    </Box>
                                  </AccordionSummary>
                                  <AccordionDetails
                                    sx={{
                                      pl: "10px",
                                      background: "#1c1b1b",
                                      borderLeft: "1px solid #fff",
                                    }}
                                    className={`C004-menu-items-${idx}-details-${Childidx}-details`}
                                  >
                                    {children &&
                                      children.length > 1 &&
                                      children.map((subChild, subChildIdx) => {
                                        return (
                                          <Box
                                            sx={{
                                              pl: "20px",
                                              background: "#1c1b1b",
                                            }}
                                            key={`${idx}-${Childidx}-${subChildIdx}`}
                                            className={`C004-menu-items-${idx}-details-${Childidx}-details-${subChildIdx}`}
                                          >
                                            <Link
                                              href={subChild.link}
                                              style={{
                                                width: "100%",
                                                textAlign: "left",
                                                color: "#a5a5a5 !important",
                                              }}
                                              className={`C004-menu-items-${idx}-details-${Childidx}-details-${subChildIdx}`}
                                            >
                                              <ChildTypography
                                                sub={1}
                                                onClick={() => setOpen(false)}
                                                className={`C004-menu-items-${idx}-details-${Childidx}-details-${subChildIdx}`}
                                              >
                                                {subChild.value}
                                              </ChildTypography>
                                            </Link>
                                          </Box>
                                        );
                                      })}
                                  </AccordionDetails>
                                </Accordion>
                              );
                            }
                            if (value2) {
                              return (
                                <Box
                                  key={`${idx}-${Childidx}`}
                                  className={`C004-menu-items-${idx}-details-${Childidx}`}
                                >
                                  <Link
                                    href={link}
                                    style={{
                                      width: "100%",
                                      textAlign: "left",
                                      color: "#000",
                                      margin: "10px 0px !important",
                                    }}
                                    className={`C004-menu-items-${idx}-details-${Childidx}`}
                                  >
                                    <ChildTypography
                                      mb="10px"
                                      mt="10px"
                                      onClick={() => setOpen(false)}
                                      className={`C004-menu-items-${idx}-details-${Childidx}`}
                                    >
                                      {value}
                                    </ChildTypography>
                                  </Link>
                                </Box>
                              );
                            }
                            return null;
                          },
                        )}
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                  <Divider
                    sx={{
                      width: "88%",
                      margin: "0 auto",
                      background: "#ffffff40",
                    }}
                  />
                </Fragment>
              );
            }
            return (
              <Fragment key={idx}>
                <ListItem
                  sx={{ padding: "6px 20px !important" }}
                  className={`C004-menu-items-${idx}`}
                >
                  <Link
                    href={item.utm ? setURL(item.link, queryParams) : item.link}
                    style={{
                      width: "100%",
                      textAlign: "left",
                      color: "#000",
                      minHeight: "48px",
                      display: "flex",
                    }}
                    className={`C004-menu-items-${idx}`}
                  >
                    <ListItemButton
                      onClick={() => setOpen(false)}
                      className={`C004-menu-items-${idx}`}
                    >
                      {item.value}
                    </ListItemButton>
                  </Link>
                </ListItem>
                <Divider
                  sx={{
                    width: "88%",
                    margin: "0 auto",
                    background: "#ffffff40",
                  }}
                />
              </Fragment>
            );
          })}
        </List>
      </Box>
    </Drawer>
  );
}
