"use client";
import Image from "next/image";
import Link from "next/link";
import styled from "@emotion/styled";
import { Fragment, useState } from "react";
import { AppBar, Box, Button, Divider, Grid, Typography } from "@mui/material";

import config from "config/env";
import SwiperSlide from "components/swipper";
import { SearchParamsHook } from "lib/utm";
import { setURL } from "utils/outclicks";
import { MenuItems } from "utils/drawerItem";

import DesktopMenu from "./DesktopMenu";
import MobileDrawer from "./MobileDrawer";

const AnnouncementBar = styled(Box)`
  background: #f1e4d8;
  padding: 9px 10px;
  font-family:
    DIN Neuzeit Grotesk,
    sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #1c1c1c;
  transition: color 0.2s ease-in-out;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  font-size: 9px;
  text-align: center;
`;

const DesktopMenuBar = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 25px 50px;
  background-color: #ffffff;
  a {
    display: flex;
    align-items: center;
  }
  @media only screen and (max-width: 1200px) {
    display: none;
  }
`;

const Logo = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 25px 50px 0px;
  background-color: #ffffff;
  a {
    display: flex;
    align-items: center;
  }
  @media only screen and (max-width: 1200px) {
    padding: 15px 50px !important;
    justify-content: center;
  }
  @media only screen and (max-width: 750px) {
    a {
      img {
        max-height: 26px !important;
        max-width: 130px !important;
      }
    }
  }
`;

const LogoBox = styled(Box)`
  position: absolute;
  left: 0px;
  z-index: 10 !important;
  @media only screen and (min-width: 1201px) {
    display: none;
  }
`;

const HeaderItems = styled(Typography)`
  font-size: 12px;
  line-height: 1.65;
  text-transform: capitalize;
  color: #1c1b1b;
  letter-spacing: 0.2em;
  font-family: DIN Neuzeit Grotesk;
  margin-right: 21px;
  margin-left: 21px;
  min-width: 10px;
  background-image: linear-gradient(#000 0 0);
  background-position: 0 100%; /*OR bottom left*/
  background-size: 0% 1px;
  background-repeat: no-repeat;
  transition:
    background-size 0.3s,
    background-position 0s 0.3s;
  &:hover {
    background-position: 100% 100%; /*OR bottom right*/
    background-size: 100% 1px;
  }
`;

const announcementBar = ["Save 20% on your first order with FB20 upto ₹1000"];

export default function Header() {
  const queryParams = SearchParamsHook();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuSubItems, setMenuSubItems] = useState([]);
  const menuOpen = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <AnnouncementBar className="C001-announcement">
        <SwiperSlide slides={announcementBar} sliderId="announcementBar" />
      </AnnouncementBar>
      <AppBar
        position="sticky"
        color="transparent"
        elevation={0}
        sx={{ zIndex: "10 !important" }}
        className="C001"
      >
        <Grid container className="C002">
          <Logo item xs={12} className="C002">
            <LogoBox className="C002-menu">
              <Button
                sx={{ color: "#2e2e2e" }}
                onClick={() => setOpen(true)}
                className="C002-menu"
              >
                <Image
                  src="/static/menuDrawerDoodlage.svg"
                  width={22}
                  height={22}
                  alt="logo"
                  priority
                  fetchPriority="high"
                  loading="eager"
                  unoptimized={true}
                  className="C002-menu"
                />
              </Button>
            </LogoBox>

            <Link
              href={setURL(`${config.brandURL}`, queryParams)}
              className="C002-logo"
            >
              <Image
                src="/static/logo.png"
                width={170}
                height={34}
                alt="logo"
                priority
                fetchPriority="high"
                loading="eager"
                unoptimized={true}
                className="C002-logo"
              />
            </Link>
          </Logo>
          <DesktopMenuBar
            item
            xs={12}
            boxShadow="0 -1px #ddd inset"
            pb="25px !important"
            className="C002-desktop-menu"
          >
            <Box
              display="flex"
              flexDirection="row"
              className="C002-desktop-menu"
            >
              {MenuItems.map((item, idx) => {
                return !item.children.length ? (
                  <Link
                    href={item.utm ? setURL(item.link, queryParams) : item.link}
                    style={{ color: "#222" }}
                    key={item.value}
                    className={`C002-desktop-menu-${idx}`}
                  >
                    <Button
                      sx={{ padding: "0px" }}
                      className={`C002-desktop-menu-${idx}`}
                    >
                      <HeaderItems className={`C002-desktop-menu-${idx}`}>
                        {item.value}
                      </HeaderItems>
                    </Button>
                  </Link>
                ) : (
                  <Fragment key={item.value}>
                    <Button
                      id={`fade-button-${idx}`}
                      onClick={(e) => {
                        handleClick(e);
                        setMenuSubItems(item.children);
                      }}
                      className={`C002-desktop-menu-${idx}`}
                      onMouseOver={(e) => {
                        handleClick(e);
                        setMenuSubItems(item.children);
                      }}
                      sx={{ padding: "0px" }}
                    >
                      <Link
                        href={item.link}
                        className={`C002-desktop-menu-${idx}`}
                      >
                        <HeaderItems className={`C002-desktop-menu-${idx}`}>
                          {item.value}
                        </HeaderItems>
                      </Link>
                    </Button>
                    <DesktopMenu
                      open={menuOpen}
                      anchorEl={anchorEl}
                      id={`fade-button-${idx}`}
                      handleClose={handleClose}
                      item={menuSubItems}
                      itemValue={item.value}
                      parentIdx={idx}
                    />
                  </Fragment>
                );
              })}
            </Box>
          </DesktopMenuBar>
        </Grid>
        <Divider sx={{ backgroundColor: "rgb(255, 255, 255)" }} />
      </AppBar>
      <MobileDrawer open={open} setOpen={setOpen} />
    </>
  );
}
